import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default"),
    (!$props.hideButtons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "buttons-left")
          ]),
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "buttons-right"),
            _createVNode(_component_a_button, {
              type: "primary",
              loading: $props.submitting,
              disabled: !$setup.meta.valid,
              class: "mt-4",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleSubmit($setup.emit('submit'))))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t($props.submitText)), 1)
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}