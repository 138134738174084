
import { Button as AButton } from 'ant-design-vue';
import { useForm } from 'vee-validate';

export default {
  name: 'TheForm',
  components: {
    AButton
  },
  props: {
    submitText: {
      type: String,
      default: 'button.submit'
    },
    submitting: {
      type: Boolean,
      default: false
    },
    hideButtons: {
      type: Boolean,
      default: false
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(_: any, { emit }: { emit: any }) {
    const { handleSubmit, meta } = useForm();

    return {
      emit,
      handleSubmit,
      meta
    };
  }
};
